<app-header3></app-header3>
<!--====== Breadcrumb part Start ======-->
    
    <!--====== Breadcrumb part End ======-->

    <!--====== Contact Section start ======-->
    <section class="contact-section contact-page section-gap-top">
        <div class="container">
            <div class="contact-info">
                <div class="row justify-content-center">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div class="illustration-img text-center">
                            <img src="assets/img/illustration/man-with-earth-02.png" alt="Image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                        <div class="contact-info-content">
                            <div class="section-title left-border mb-40">
                                <span class="title-tag">Get In Touch</span>
                                <h2 class="title">Need Any Help For Business & Consulting</h2>
                            </div>
                            <p>
                                We are ready to lead you to a success in your IT endevours
                            </p>

                            <ul>
                                <li class="phone">
                                    <a href="tel:+1 6478498862"><i class="far fa-phone"></i>+1 (647) 849-8862</a>
                                </li>
                                <li><i class="far fa-envelope-open"></i><a routerLink='/contact-us'>sales@prosolv.ca</a></li>
                                <li><i class="far fa-map-marker-alt"></i>Toronto Canada</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="contact-form grey-bg">
                <div class="row no-gutters justify-content-center">
                    <div class="col-10">
                        <div class="section-title text-center mb-40">
                            <h2 class="title">Don’t Hesited To Contact Us</h2>
                        </div>

                        <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="text" name="Fullname" formControlName="Fullname" placeholder="Your Full Name">
                                        <span class="icon"><i class="far fa-user-circle"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="email" name="Email" formControlName="Email" placeholder="Your Email Address">
                                        <span class="icon"><i class="far fa-envelope-open"></i></span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="input-group mb-30">
                                        <input type="text" name="Phone" formControlName="Phone" placeholder="Your Phone">
                                        <span class="icon"><i class="far fa-phone"></i></span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group textarea mb-30">
                                        <textarea name="Message" formControlName="Message" placeholder="Write Message"></textarea>
                                        <span class="icon"><i class="far fa-pencil"></i></span>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <button type="submit" class="main-btn" [disabled]="!FormData.valid">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="container-fluid container-1600">
            <div class="contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184551.8085817001!2d-79.51814207805295!3d43.718403811543084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1611242881238!5m2!1sen!2sca" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
        </div>
    </section>
    <!--====== Contact Section start ======-->

    <!--====== Client Area Start ======-->
    <!-- <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap line-top">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/contact-us' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!--====== Client Area End ======-->
    <!-- <app-footer1></app-footer1> -->