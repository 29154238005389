<app-header3></app-header3>
<!--====== Banner Section start ======-->
    <section class="banner-section banner-section-three">
        <div class="banner-slider">
            <div class="single-banner">
                <div class="container-fluid container-1600">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="banner-content">
                                <span class="promo-text wow fadeInLeft" data-wow-duration="1500ms"
                                    data-wow-delay="400ms">Splunk Consulting</span>
                                <h1 class="wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="500ms">
                                    Trusted and Experienced <br> Splunk Consulting
                                </h1>
                                <ul class="btn-wrap">
                                    <li class="wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                        <a routerLink='/contact-us' class="main-btn">Contact Us</a>
                                    </li>
                                    <li class="wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="700ms">
                                        <a routerLink='/service-detail' class="main-btn main-btn-3">Our Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-7 d-none d-md-block">
                            <div class="banner-img text-right wow fadeInRight" data-wow-duration="1500ms"
                                data-wow-delay="800ms">
                                <img src="assets/img/illustration/04.png" alt="illustration">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-shapes">
                    <div class="one"></div>
                    <div class="two"></div>
                    <div class="three"></div>
                </div>
                <div class="banner-line">
                    <img src="assets/img/lines/17.png" alt="Image">
                </div>
            </div>
        </div>
    </section>
    <!--====== Banner Section end ======-->

    <!--====== Service Section Start ======-->
    <section class="service-section section-gap">
        <div class="container">
            <!-- Section Title -->
            <div class="section-title text-center both-border mb-50">
                <span class="title-tag">Services</span>
                <h2 class="title">Transforming Data into Insight</h2>
                <h4>We make DATA the accelerator of your business: we protect and transform your DATA into relevant knowledge for making strategic Security and Business decisions.</h4>
            </div>
            <!-- Services Boxes -->
            <div class="row service-boxes justify-content-center">
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div class="service-box-three" style="height: 500px;">
                        <div class="icon">
                            <img src="assets/img/icons/01.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Customer Oriented</a></h3>
                        <p>We develop solutions tailored to the needs of our customers</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                    <div class="service-box-three" style="height: 500px;">
                        <div class="icon">
                            <img src="assets/img/icons/02.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Innovate</a></h3>
                        <p>We continuously work on the development of innovative solutions, applying agile and automated methodologies</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="800ms">
                    <div class="service-box-three" style="height: 500px;">
                        <div class="icon">
                            <img src="assets/img/icons/03.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Expert Team</a></h3>
                        <p>Multidisciplinary and committed, with extensive experience in the implementation and development of new solutions</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-10 wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="1s">
                    <div class="service-box-three" style="height: 500px;">
                        <div class="icon">
                            <img src="assets/img/icons/04.png" alt="Icon">
                        </div>
                        <h3><a routerLink='/service-detail'>Experience</a></h3>
                        <p>We have a great experience and the endorsement of the satisfaction of our clients</p>
                        <a routerLink='/service-detail' class="service-link">
                            <i class="fal fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Service Section End ======-->

    <!--====== About Section start ======-->
    <section class="about-section-three section-gap">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10 order-2 order-lg-1">
                    <div class="about-text-three">
                        <div class="section-title left-border mb-40">
                            <span class="title-tag">About Us</span>
                            <h2 class="title">Information Technology <br> Business Consulting </h2>
                        </div>
                        <p class="mb-25">
                            We are committed to changing your business with the assistance of most brilliant of advancement 
                            and applying the most recent advances for tackling your IT concerns.
                        </p>
                        <p>
                            
                        </p>
                        <ul class="about-list">
                            <li> <i class="far fa-check"></i> Splunk Professional Services and App Development</li>
                            <li> <i class="far fa-check"></i> Data Analytics Solutions </li>
                            <li> <i class="far fa-check"></i> Specialized Software Development</li>
                            <li> <i class="far fa-check"></i> Risk Assessment, Planning and Recovery Solutions </li>
                            <li> <i class="far fa-check"></i> Custom Web Development </li>
                        </ul>
                        <a routerLink='/service-detail' class="main-btn main-btn-4">Learn More</a>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-10 order-1 order-lg-2">
                    <div class="about-tile-gallery">
                        <img src="assets/img/video-bg/poster-3.jpg" alt="Image" class="image-one wow fadeInRight"
                            data-wow-duration="1500ms" data-wow-delay="400ms">
                        <img src="assets/img/video-bg/poster-2.jpg" alt="Image" class="image-two wow fadeInRight"
                            data-wow-duration="1500ms" data-wow-delay="600ms">
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <!--====== About Section end ======-->

    <!--====== Contact Section start ======-->
    <section class="contact-section boxed-style-with-map">
        <div class="container">
            <div class="contact-inner mt-negative grey-bg">
                <div class="row no-gutters">
                    <div class="col-lg-6">
                        <div class="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184551.8085817001!2d-79.51814207805295!3d43.718403811543084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1611242881238!5m2!1sen!2sca" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="contact-form">
                            <div class="section-title left-border mb-30">
                                <span class="title-tag">Drop A Message</span>
                                <h2 class="title">Don’t Hesitate To Contact Us</h2>
                            </div>

                            <!-- <form action="#">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="input-group mb-30">
                                            <input type="text" placeholder="Your Full Name">
                                            <span class="icon"><i class="far fa-user-circle"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-group mb-30">
                                            <input type="email" placeholder="Your Email Address">
                                            <span class="icon"><i class="far fa-envelope-open"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-group select mb-30">
                                            <select>
                                                <option value="subject" selected>Subject</option>
                                                <option value="1">Creative Idea</option>
                                                <option value="2">Relationship</option>
                                                <option value="3">Business Strategy</option>
                                                <option value="4">Productivity</option>
                                                <option value="5">It Solutions</option>
                                                <option value="5">Product Design</option>
                                            </select>
                                            <span class="icon"><i class="far fa-angle-down"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="input-group textarea mb-30">
                                            <textarea placeholder="Write Message"></textarea>
                                            <span class="icon"><i class="far fa-pencil"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="button" class="main-btn">Send Message</button>
                                    </div>
                                </div>
                            </form> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--====== Contact Section start ======-->

    <!--====== Client Area Start ======-->
    <!-- <section class="client-section">
        <div class="container">
            <div class="client-slider section-gap">
                <div class="row align-items-center justify-content-between" id="clientSlider">
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/01.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/02.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/03.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/04.png" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <a routerLink='/homepage3' class="client-img d-block text-center">
                            <img src="assets/img/clients/05.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!--====== Client Section End ======-->
    <app-footer2></app-footer2>