<header class="header-three sticky-header">
        <!-- Header Menu  -->
        <div class="header-nav">
            <div class="container-fluid">
                <div class="nav-container">
                    <!-- Site Logo -->
                    <div class="site-logo">
                        <!-- <a routerLink='/'><img src="assets/img/logo-3.png" alt="Logo"></a> -->
                        <a routerLink='/'><img src="assets/img/logo-4.png" alt="Logo"></a>
                    </div>

                    <!-- Main Menu -->
                    <div class="nav-menu d-lg-flex align-items-center">

                        <!-- Navbar Close Icon -->
                        <div class="navbar-close">
                            <div class="cross-wrap"><span></span><span></span></div>
                        </div>

                        <!-- Mneu Items -->
                        <!-- <div class="menu-items">
                            <ul>
                                <li class="has-submemu">
                                    <a href="javascript:void(0)">Home</a>
                                    <ul class="submenu">
                                        <li><a routerLink='/' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home One</a></li>
                                        <li><a routerLink='/homepage2' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Two</a> </li>
                                        <li><a routerLink='/homepage3' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                                    </ul>
                                </li>
                                <li class="has-submemu">
                                    <a href="javascript:void(0)">Pages</a>
                                    <ul class="submenu">
                                        <li><a routerLink='/about-us' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About us</a></li>
                                        <li><a routerLink='/team' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Our Team</a> </li>
                                        <li><a routerLink='/team-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Team Details</a></li>
                                        <li><a routerLink='/career' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                                        <li><a routerLink='/FAQ' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                                    </ul>
                                </li>
                                <li class="has-submemu">
                                    <a routerLink='/service-one' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a>
                                    <ul class="submenu">
                                        <li><a routerLink='/service-two' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services Two</a></li>
                                        <li><a routerLink='/service-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                                    </ul>
                                </li>
                                <li class="has-submemu">
                                    <a routerLink='/blog-grid' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a>
                                    <ul class="submenu">
                                        <li><a routerLink='/blog-standard' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Standard</a></li>
                                        <li><a routerLink='/blog-grid' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                        <li><a routerLink='/blog-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                                    </ul>
                                </li>
                                <li class="has-submemu">
                                    <a routerLink='/portfolio-one' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Portfolio</a>
                                    <ul class="submenu">
                                        <li><a routerLink='/portfolio-two' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Portfolio Two</a></li>
                                        <li><a routerLink='/portfolio-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Portfolio Details</a></li>
                                    </ul>
                                </li>
                                <li class="has-submemu">
                                    <a routerLink='/shop' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Shop</a>
                                    <ul class="submenu">
                                        <li><a routerLink='/shop-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Product Details</a></li>
                                    </ul>
                                </li>
                                <li><a routerLink='/contact-us' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            </ul>
                        </div> -->
                        <div class="menu-items">
                            <ul>
                                <li>
                                    <a routerLink='/' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
                                </li>
                                <!-- <li>
                                    <a routerLink='/service-detail' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a>
                                </li>
                                <li>
                                    <a routerLink='/projects' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects</a>
                                </li>
                                <li>
                                    <a routerLink='/blog-grid' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a>
                                </li> -->
                                <li><a routerLink='/contact-us' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            </ul>
                        </div>
                        <!-- Pushed Item -->
                        <div class="nav-pushed-item"></div>
                    </div>

                    <!-- Navbar Extra  -->
                    <div class="navbar-extra d-flex align-items-center">
                        <!-- Social Link -->
                        <div class="menu-social nav-push-item">
                            <div class="menu-social-link">
                                <a href="https://www.linkedin.com/in/prosolv-consulting-49ab49204/" target="_blank"><i class="fab fa-linkedin"></i></a>
                                <a href="mailto:sales@prosolv.ca" target="_blank"><i class="fas fa-envelope"></i></a>
                                <a href="https://twitter.com/ProsolvC" target="_blank"><i class="fab fa-twitter"></i></a>
                            </div>
                        </div>
                        <!-- Navbar Toggler -->
                        <div class="navbar-toggler">
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>